import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

// Define the type for a standing record
interface StandingRecord {
  player_id: number;
  first_name: string;
  last_name: string;
  total_round_points: number;
  total_round_team_points: number;
  total_tournament_points: number;
  total_tournament_team_points: number;
  total_points: number;
}

interface RegFinishesRecord {
  player_id: number;
  first_name: string;
  last_name: string;
  regular_season_starts: number;
  rank_1_finishes: number;
  rank_2_finishes: number;
  rank_3_finishes: number;
  rank_4_finishes: number;
  rank_5_finishes: number;
  rank_6_finishes: number;
  rank_7_finishes: number;
}

interface IndividualPerformanceRecord {
  player_id: number;
  first_name: string;
  last_name: string;
  regular_season_average: number | null;
  major_average: number | null;
  combined_average: number | null;
}

interface Season {
  season_id: number;
  season_year: string;
}

const Standings: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [standings, setStandings] = useState<StandingRecord[]>([]);
  const [regFinishes, setRegFinishes] = useState<RegFinishesRecord[]>([]);
  const [individualPerformance, setIndividualPerformance] = useState<IndividualPerformanceRecord[]>([]);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<string>(''); // Default to no selection
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;

  // Fetch seasons
  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get<Season[]>(`${APIURL}/seasons`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSeasons(response.data);
        if (response.data.length > 0) {
          setSelectedSeason(response.data[0].season_year); // Default to the first season's year
        }
      } catch (err) {
        setError('Failed to fetch seasons.');
      }
    };

    if (isAuthenticated) {
      fetchSeasons();
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, APIURL]);

  // Fetch standings and other data when the selected season changes
  useEffect(() => {
    if (!selectedSeason) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();

        const [standingsResponse, regFinishesResponse, individualPerformanceResponse] = await Promise.all([
          axios.get<StandingRecord[]>(`${APIURL}/standings?season_year=${selectedSeason}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get<RegFinishesRecord[]>(`${APIURL}/regfinishes?season_year=${selectedSeason}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get<IndividualPerformanceRecord[]>(`${APIURL}/averagescores?season_year=${selectedSeason}`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        setStandings(standingsResponse.data);
        setRegFinishes(regFinishesResponse.data);
        setIndividualPerformance(individualPerformanceResponse.data);
        setError(null);
      } catch (err) {
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedSeason, getAccessTokenSilently, APIURL]);

  if (isLoading || loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h3>Standings</h3>

      {/* Season Filter */}
      <label>
        Filter by Season:
        <select value={selectedSeason} onChange={(e) => setSelectedSeason(e.target.value)}>
          {seasons.map((season) => (
            <option key={season.season_id} value={season.season_year}>
              {season.season_year}
            </option>
          ))}
        </select>
      </label>
      <br /><br />

      {/* Standings Table */}
      <table>
        <thead>
          <tr>
            <th>Player ID</th>
            <th>Player</th>
            <th>Regular Season Points</th>
            <th>Regular Season Team Points</th>
            <th>Major Points</th>
            <th>Major Team Points</th>
            <th>Total Points</th>
          </tr>
        </thead>
        <tbody>
          {standings.map((record) => (
            <tr key={record.player_id}>
              <td>{record.player_id}</td>
              <td>{record.first_name} {record.last_name}</td>
              <td>{record.total_round_points}</td>
              <td>{record.total_round_team_points}</td>
              <td>{record.total_tournament_points}</td>
              <td>{record.total_tournament_team_points}</td>
              <td>{record.total_points}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br /><br />

      {/* Regular Season Finishes */}
      <h3>Regular Season Finishes</h3>
<table>
  <thead>
    <tr>
      <th>Player ID</th>
      <th>Player</th>
      <th>Starts</th>
      <th>R1</th>
      <th>R2</th>
      <th>R3</th>
      <th>R4</th>
      <th>R5</th>
      <th>R6</th>
      <th>R7</th>
    </tr>
  </thead>
  <tbody>
    {regFinishes.map((record) => (
      <tr key={record.player_id}>
        <td>{record.player_id}</td>
        <td>{record.first_name} {record.last_name}</td>
        <td><b>{record.regular_season_starts}</b></td>
        <td>
          <b>{record.rank_1_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_1_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
        <td>
          <b>{record.rank_2_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_2_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
        <td>
          <b>{record.rank_3_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_3_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
        <td>
          <b>{record.rank_4_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_4_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
        <td>
          <b>{record.rank_5_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_5_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
        <td>
          <b>{record.rank_6_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_6_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
        <td>
          <b>{record.rank_7_finishes}</b> ({record.regular_season_starts > 0 
            ? ((record.rank_7_finishes / record.regular_season_starts) * 100).toFixed(1) + '%' 
            : 'N/A'})
        </td>
      </tr>
    ))}
  </tbody>
</table>

      {/* Individual Performance */}
      <h3>Individual Performance</h3>
      <table>
        <thead>
          <tr>
            <th>Player ID</th>
            <th>Player</th>
            <th>Regular Season Average</th>
            <th>Major Average</th>
            <th>Combined Average</th>
          </tr>
        </thead>
        <tbody>
          {individualPerformance.map((record) => (
            <tr key={record.player_id}>
              <td>{record.player_id}</td>
              <td>{record.first_name} {record.last_name}</td>
              <td>{record.regular_season_average || 'N/A'}</td>
              <td>{record.major_average || 'N/A'}</td>
              <td>{record.combined_average || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Standings;