import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface Player {
  player_id: number;
  first_name: string;
  last_name: string;
  handedness: string;
  team: string;
  handicap: number;
  tournament_handicap: number;
  league_status: string;
}

const Players: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState<Player[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [editPlayerId, setEditPlayerId] = useState<number | null>(null);
  const [editedPlayer, setEditedPlayer] = useState<Partial<Player>>({});
  const [sortConfig, setSortConfig] = useState<{ key: keyof Player; direction: 'asc' | 'desc' } | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;
  const [newPlayer, setNewPlayer] = useState<{ first_name: string; last_name: string }>({
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${APIURL}/players`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        setData(responseData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const handleSort = (key: keyof Player) => {
    let direction: 'asc' | 'desc' = 'asc';

    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });
    setData((prevData) => {
      const sortedData = [...prevData].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      return sortedData;
    });
  };

  const handleEditClick = (player: Player) => {
    setEditPlayerId(player.player_id);
    setEditedPlayer({ ...player });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if ((name === 'handicap' || name === 'tournament_handicap') && !/^\d*$/.test(value)) {
      return; // Only allow numeric input
    }

    // Translate "None" to an empty string for the team field
    const processedValue = name === 'team' && value === 'None' ? '' : value;

    setEditedPlayer((prev) => ({
      ...prev,
      [name]: processedValue === '' ? 0 : processedValue, // Convert empty string to 0 for numeric fields
    }));
  };

  const handleSave = async (playerId: number) => {
    try {
      const token = await getAccessTokenSilently();
      const updatedPlayer = {
        ...editedPlayer,
        handicap: editedPlayer.handicap || 0, // Ensure numeric fields default to 0
        tournament_handicap: editedPlayer.tournament_handicap || 0,
      };

      const response = await fetch(`${APIURL}/players/${playerId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedPlayer),
      });

      if (!response.ok) {
        throw new Error('Failed to save player data.');
      }

      setData((prevData) =>
        prevData.map((player) =>
          player.player_id === playerId ? { ...player, ...updatedPlayer } : player
        )
      );

      setEditPlayerId(null);
      setEditedPlayer({});
    } catch (error) {
      console.error('Error saving player data:', error);
      setError('Error saving player data.');
    }
  };

  const handleCancel = () => {
    setEditPlayerId(null);
    setEditedPlayer({});
  };

  const handleAddPlayer = async () => {
    if (!newPlayer.first_name || !newPlayer.last_name) {
      alert('First Name and Last Name are required.');
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${APIURL}/players`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newPlayer),
      });

      if (!response.ok) {
        throw new Error('Failed to add player.');
      }

      const addedPlayer = await response.json();
      setData((prevData) => [...prevData, addedPlayer]);

      // Clear input fields
      setNewPlayer({ first_name: '', last_name: '' });
    } catch (error) {
      console.error('Error adding player:', error);
      setError('Error adding player.');
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Golfskies League Members</h2>
      {/* Add Player Section */}
      <div>
        <input
          type="text"
          placeholder="First Name"
          value={newPlayer.first_name}
          onChange={(e) => setNewPlayer((prev) => ({ ...prev, first_name: e.target.value }))}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={newPlayer.last_name}
          onChange={(e) => setNewPlayer((prev) => ({ ...prev, last_name: e.target.value }))}
        />
        <button onClick={handleAddPlayer}>Add Player</button>
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('player_id')}>ID</th>
            <th onClick={() => handleSort('first_name')}>First Name</th>
            <th onClick={() => handleSort('last_name')}>Last Name</th>
            <th onClick={() => handleSort('handedness')}>Handedness</th>
            <th onClick={() => handleSort('team')}>Team</th>
            <th onClick={() => handleSort('handicap')}>Handicap</th>
            <th onClick={() => handleSort('tournament_handicap')}>Tournament Handicap</th>
            <th onClick={() => handleSort('league_status')}>League Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((player) => (
            <tr key={player.player_id}>
              <td>{player.player_id}</td>
              <td>
                {editPlayerId === player.player_id ? (
                  <input
                    type="text"
                    name="first_name"
                    value={editedPlayer.first_name || ''}
                    onChange={handleInputChange}
                  />
                ) : (
                  player.first_name
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <input
                    type="text"
                    name="last_name"
                    value={editedPlayer.last_name || ''}
                    onChange={handleInputChange}
                  />
                ) : (
                  player.last_name
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <select
                    name="handedness"
                    value={editedPlayer.handedness || ''}
                    onChange={handleInputChange}
                  >
                    <option value="Right">Right</option>
                    <option value="Left">Left</option>
                  </select>
                ) : (
                  player.handedness
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <select
                    name="team"
                    value={editedPlayer.team || ''}
                    onChange={handleInputChange}
                  >
                    <option value="Righties">Righties</option>
                    <option value="Lefties">Lefties</option>
                    <option value="None">None</option>
                  </select>
                ) : (
                  player.team || 'None'
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <input
                    type="text"
                    name="handicap"
                    value={editedPlayer.handicap || ''}
                    onChange={handleInputChange}
                  />
                ) : (
                  player.handicap
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <input
                    type="text"
                    name="tournament_handicap"
                    value={editedPlayer.tournament_handicap || ''}
                    onChange={handleInputChange}
                  />
                ) : (
                  player.tournament_handicap
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <select
                    name="league_status"
                    value={editedPlayer.league_status || ''}
                    onChange={handleInputChange}
                  >
                    <option value="Member">Member</option>
                    <option value="Guest">Guest</option>
                  </select>
                ) : (
                  player.league_status
                )}
              </td>
              <td>
                {editPlayerId === player.player_id ? (
                  <>
                    <button onClick={() => handleSave(player.player_id)}>Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                  </>
                ) : (
                  <button onClick={() => handleEditClick(player)}>Edit</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Players;
