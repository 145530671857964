import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Players from './pages/Players';
import Rounds from './pages/Rounds';
import About from './pages/About';
import Majors from './pages/Majors';
import Seasons from './pages/Seasons'
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import AuthButton from './components/AuthButton';
import ProtectedComponent from './components/ProtectedComponent';
import banner from './banner.webp';
import './App.css';
import Standings from './pages/Standings';



const App: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div id="banner">
        <img src={banner} alt="Broskies Golf Association" width= '1000' height="125" />
      </div>
      <Navbar />
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Rounds />} />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
        <Route path="/rounds" element={<ProtectedRoute element={<Rounds />} />} />
        <Route path="/standings" element={<ProtectedRoute element={<Standings />} />} />
        <Route path="/majors" element={<ProtectedRoute element={<Majors />} />} />
        <Route path="/players" element={<ProtectedRoute element={<Players />} />} />
        <Route path="/about" element={<ProtectedRoute element={<About />} />} />
        <Route path="/seasons" element={<ProtectedRoute element={<Seasons />} />} />
      </Routes>
    </>
  );
}

export default App;
