import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

interface SeasonRecord {
  season_id: number;
  season_year: string;
  season_start: string; // ISO format date
  season_end: string;   // ISO format date
}

const Seasons: React.FC = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [seasons, setSeasons] = useState<SeasonRecord[]>([]);
  const [newSeason, setNewSeason] = useState({
    season_year: '',
    season_start: '',
    season_end: '',
  });
  const [editingSeason, setEditingSeason] = useState<SeasonRecord | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const APIURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await axios.get<SeasonRecord[]>(`${APIURL}/seasons`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSeasons(response.data);
      } catch (err) {
        setError('Failed to fetch seasons.');
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchSeasons();
    } else if (!isLoading) {
      loginWithRedirect();
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewSeason((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSeason = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${APIURL}/seasons`,
        newSeason,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSeasons([...seasons, response.data]);
      setNewSeason({ season_year: '', season_start: '', season_end: '' });
    } catch (err) {
      setError('Failed to add season.');
    }
  };

  const handleEditSeason = async () => {
    if (!editingSeason) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${APIURL}/seasons/${editingSeason.season_id}`,
        editingSeason,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSeasons(
        seasons.map((season) =>
          season.season_id === editingSeason.season_id ? response.data : season
        )
      );
      setEditingSeason(null);
    } catch (err) {
      setError('Failed to edit season.');
    }
  };

  const handleDeleteSeason = async (seasonId: number) => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`${APIURL}/seasons/${seasonId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSeasons(seasons.filter((season) => season.season_id !== seasonId));
    } catch (err) {
      setError('Failed to delete season.');
    }
  };

  if (isLoading || loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h3>Seasons Management</h3>

      {/* Add New Season */}
      <div>
        <h4>Add New Season</h4>
        <input
          type="text"
          name="season_year"
          placeholder="Season Year"
          value={newSeason.season_year}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="season_start"
          placeholder="Start Date"
          value={newSeason.season_start}
          onChange={handleInputChange}
        />
        <input
          type="date"
          name="season_end"
          placeholder="End Date"
          value={newSeason.season_end}
          onChange={handleInputChange}
        />
        <button onClick={handleAddSeason}>Add Season</button>
      </div>

      <br />

      {/* List Seasons */}
      <table>
        <thead>
          <tr>
            <th>Season ID</th>
            <th>Year</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {seasons.map((season) => (
            <tr key={season.season_id}>
              <td>{season.season_id}</td>
              <td>
                {editingSeason?.season_id === season.season_id ? (
                  <input
                    type="text"
                    value={editingSeason.season_year}
                    onChange={(e) =>
                      setEditingSeason({
                        ...editingSeason,
                        season_year: e.target.value,
                      })
                    }
                  />
                ) : (
                  season.season_year
                )}
              </td>
              <td>
                {editingSeason?.season_id === season.season_id ? (
                  <input
                    type="date"
                    value={editingSeason.season_start}
                    onChange={(e) =>
                      setEditingSeason({
                        ...editingSeason,
                        season_start: e.target.value,
                      })
                    }
                  />
                ) : (
                  season.season_start
                )}
              </td>
              <td>
                {editingSeason?.season_id === season.season_id ? (
                  <input
                    type="date"
                    value={editingSeason.season_end}
                    onChange={(e) =>
                      setEditingSeason({
                        ...editingSeason,
                        season_end: e.target.value,
                      })
                    }
                  />
                ) : (
                  season.season_end
                )}
              </td>
              <td>
                {editingSeason?.season_id === season.season_id ? (
                  <button onClick={handleEditSeason}>Save</button>
                ) : (
                  <button onClick={() => setEditingSeason(season)}>Edit</button>
                )}
                <button onClick={() => handleDeleteSeason(season.season_id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Seasons;
