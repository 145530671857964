import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/navbar.css'; // Assuming you'll add your CSS in a separate file
import AuthButton from './AuthButton'; // Adjust the path if necessary


const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <button className="navbar-toggle" onClick={toggleNavbar}>
        ☰ Navigation
      </button>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li>
          <Link to="/rounds">Rounds</Link>
        </li>
        <li>
          <Link to="/standings">Standings</Link>
        </li>
        <li>
          <Link to="/majors">Majors</Link>
        </li>
        <li>
          <Link to="/seasons">Seasons</Link>
        </li>
        <li>
          <Link to="/players">Players</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <AuthButton /> {/* Include the AuthButton here */}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
