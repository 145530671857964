import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth0 } from '@auth0/auth0-react';

interface DropdownItem {
  course_id: string;
  course_name: string;
  course_teebox_id: string;
  course_teebox_name: string;
}

interface Player {
  player_id: number;
  full_name: string;
  updatedScores: number;
}

interface Tournament {
  tournament_id: string;
  tournament_name: string;
  start_date: string;
  end_date: string;
}

const DropdownComponent: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const [playerScores, setPlayerScores] = useState<number[]>([]);
  const [playerFields, setPlayerFields] = useState(Array.from({ length: 2 }));
  const [selectedTournamentId, setSelectedTournamentId] = useState<string>('');
  const [selectedMajorIdentifier, setSelectedMajorIdentifier] = useState<string>('');
  const [tournaments, setTournaments] = useState<Tournament[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dropdownTeeboxData, setDropdownTeebox] = useState<DropdownItem[]>([]);
  const [teeBoxSelectedValue, teeBoxSetSelectedValue] = useState<string>('');
  const [date, setDate] = useState<Date | null>(new Date());
  const [leftyVsRighty, setLeftyVsRighty] = useState<boolean>(false);
  const [numberOfHoles, setNumberOfHoles] = useState<string>('18'); // New state for number of holes
  const APIURL = process.env.REACT_APP_API_URL;
  const [playerDQ, setPlayerDQ] = useState<boolean[]>([]); // Track DQ status for each player


  useEffect(() => {
    if (selectedMajorIdentifier === 'YES') {
      setLoading(true);
      getAccessTokenSilently().then((token) => {
        axios
          .get(`${APIURL}/tournaments`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setTournaments(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching tournaments:', error);
            setLoading(false);
          });
      });
    }
  }, [selectedMajorIdentifier, getAccessTokenSilently]);

  const addPlayerField = () => {
    setPlayerFields((prevFields) => [...prevFields, {}]);
    setPlayerDQ((prevDQ) => [...prevDQ, false]); // Add default false for new players

  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      axios
        .get(`${APIURL}/courses`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => setDropdownData(response.data))
        .catch((error) => console.error('Error fetching courses:', error));
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      axios
        .get(`${APIURL}/playersAddedtoRound`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => setPlayers(response.data))
        .catch((error) => console.error('Error fetching players:', error));
    });
  }, [getAccessTokenSilently]);

  const handleSelectPlayer = (playerId: number, dropdownIndex: number) => {
    let newSelectedPlayers = [...selectedPlayers];
    newSelectedPlayers[dropdownIndex] = playerId;
    newSelectedPlayers = newSelectedPlayers.filter((id) => id !== undefined && id !== null);
    setSelectedPlayers(newSelectedPlayers);
  };

  const availablePlayers = (index: number): Player[] => {
    return players.filter(
      (p) => !selectedPlayers.includes(p.player_id) || selectedPlayers[index] === p.player_id
    );
  };

  const handleDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    setSelectedValue(id);
    const fetchSecondDropdownOptions = async (selectedValue: string) => {
      setIsLoading(true);
      setError(null);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${APIURL}/teeboxes?id=${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: DropdownItem[] = await response.json();
        setDropdownTeebox(data);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    try {
      fetchSecondDropdownOptions(selectedValue);
      teeBoxSetSelectedValue('');
    } catch (error) {
      console.error('Error sending ID to backend:', error);
    }
  };

  const handleScoreChange = (score: number, index: number) => {
    const newScores = [...playerScores];
    newScores[index] = score;
    setPlayerScores(newScores);
  };

  const toggleDQ = (index: number) => {
    const newDQ = [...playerDQ];
    newDQ[index] = !newDQ[index];
    setPlayerDQ(newDQ);

    // Reset the score if DQ is toggled on
    if (newDQ[index]) {
      const newScores = [...playerScores];
      setPlayerScores(newScores);
    }
  };

  const teeBoxHandleDropdownChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const teeBoxid = event.target.value;
    teeBoxSetSelectedValue(teeBoxid);
  };

  const saveRoundData = async () => {
    if (!date) {
      console.error('Date is null');
      return;
    }
    
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];

    const roundData = {
      date: localDate,
      course: selectedValue,
      courseteebox: teeBoxSelectedValue,
      tournamentid: selectedTournamentId,
      players: selectedPlayers.map((playerId, index) => ({
        player_id: playerId,
        score: playerScores[index] || 0,
        is_disqualified: playerDQ[index]
      })),
      lefty_vs_righty: leftyVsRighty,
      number_of_holes: numberOfHoles, // Include number of holes in the round data
    };

    if (!Array.isArray(players)) {
      console.error('players is not an array');
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${APIURL}/addNewRound`, { roundData }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // alert('Round data saved successfully!');
    } catch (error) {
      console.error('Failed to save round data', error);
      // alert('Failed to save round data.');
    }
  };

  const deletePlayerField = (index: number) => {
    if (selectedPlayers.length <= 2) {
      alert('At least two players are required.');
      return;
    }

    setPlayerFields((prevFields) => prevFields.filter((_, fieldIndex) => fieldIndex !== index));
    setSelectedPlayers((prevPlayers) => prevPlayers.filter((_, playerIndex) => playerIndex !== index));
    setPlayerScores((prevScores) => prevScores.filter((_, scoreIndex) => scoreIndex !== index));
    setPlayerDQ((prevDQ) => prevDQ.filter((_, dqIndex) => dqIndex !== index));

  };

  const formatDate = (dateString: string): string => {
    const tournamentdate = new Date(dateString);
    return tournamentdate.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' });
  };

  return (
    <form id="playerRoundForm">
      <div>
        <div>
          <label>Is this associated with a tournament?</label>
          <select
            value={selectedMajorIdentifier}
            onChange={(e) => setSelectedMajorIdentifier(e.target.value)}
            className="form-control"
          >
            <option value="" disabled>Select...</option>
            <option value="YES">YES</option>
            <option value="NO">NO</option>
          </select>
        </div>

        {selectedMajorIdentifier === 'YES' && (
          <div>
            <label>What tournament is this associated with?</label>
            <select
              disabled={loading}
              className="form-control"
              value={selectedTournamentId}
              onChange={(e) => setSelectedTournamentId(e.target.value)}
            >
              <option value="" disabled>Select a tournament...</option>
              {tournaments.map((tournament) => (
                <option key={tournament.tournament_id} value={tournament.tournament_id}>
                  {tournament.tournament_name +
                    (tournament.start_date ? ` - ${formatDate(tournament.start_date)}` : '') +
                    (tournament.end_date ? ` to ${formatDate(tournament.end_date)}` : '')}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <br />
      <div className="mb-3">
        <label htmlFor="roundDate" className="form-label">What date was the round played?</label><br />
        <DatePicker selected={date} onChange={(date) => setDate(date)} required /><br />
        <br /><label htmlFor="coursesDropdown" className="form-label">Which course was played?</label>
        <select
          className="form-select"
          id="coursesDropdown"
          value={selectedValue}
          onChange={handleDropdownChange}
          defaultValue="Select a course"
          required
        >
          <option value="" disabled hidden>Select a course</option>
          {dropdownData.map((item, index) => (
            <option key={index} value={item.course_id}>
              {item.course_name}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor="coursesTeeBoxDropdown" className="form-label">Which teebox was played?</label>
        <select
          className="form-select"
          id="courseSelectedWhichBox"
          value={teeBoxSelectedValue}
          onChange={teeBoxHandleDropdownChange}
          defaultValue="Select a tee box"
          required
        >
          <option value="" disabled hidden>Select a tee box</option>
          {dropdownTeeboxData.map((item, index) => (
            <option key={index} value={item.course_teebox_id}>
              {item.course_teebox_name}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor="holesDropdown" className="form-label">How many holes were played?</label>
        <select
          className="form-select"
          id="holesDropdown"
          value={numberOfHoles}
          onChange={(e) => setNumberOfHoles(e.target.value)}
          required
        >
          <option value="18">18</option>
          <option value="9">9</option>
        </select>
        <br />
        <h5>Who played in the round?</h5>
        <div>
          {playerFields.map((_, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <select
                value={selectedPlayers[index] ?? ''}
                onChange={(e) => handleSelectPlayer(Number(e.target.value), index)}
                required={true}
              >
                <option value="">Select a Player</option>
                {availablePlayers(index).map((player) => (
                  <option key={player.player_id} value={player.player_id}>
                    {player.full_name}
                  </option>
                ))}
              </select>
              <input
                type="number"
                min="0"
                max="200"
                required={!!selectedPlayers[index]}
                onKeyDown={(e) =>
                  (e.key === '.' || e.key === '-' || e.key === '+' || e.key === 'e') && e.preventDefault()
                }
                value={playerScores[index] ?? ''}
                onChange={(e) => handleScoreChange(Number(e.target.value), index)}
                style={{ marginLeft: '10px' }}
              />
              <button
                type="button"
                onClick={() => toggleDQ(index)}
                style={{
                  backgroundColor: playerDQ[index] ? '#dc3545' : '#6c757d', // Red for active, gray for inactive
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  padding: '5px 10px',
                  marginLeft: '10px'
                }}
              >
                {playerDQ[index] ? 'DQ' : 'DQ'}
              </button>
              <button
                type="button"
                onClick={() => deletePlayerField(index)}
                disabled={selectedPlayers.length <= 2}
                style={{ marginLeft: '10px' }}
              >
                Delete Player
              </button>
            </div>
          ))}
          <button type="button" onClick={addPlayerField}>Add Player</button>
        </div>
        <hr />
        <ul id="peopleList" className="list-group mt-3" />
        {selectedMajorIdentifier !== 'YES' && (
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={leftyVsRighty}
                onChange={(e) => setLeftyVsRighty(e.target.checked)}
              />
              Is this round considered Lefty vs Righties?
            </label>
          </div>
        )}
        <button onClick={saveRoundData}>Save Round</button>
      </div>
    </form>
  );
};

export default DropdownComponent;
